export class CalendarEvent {
    name: string;
    location: string;
    address_one: string;
    address_two: string;
    city: string;
    state: number;
    zip: string;
    date_start: Date;
    date_end: Date;
    details: string;
    contact: string;
    contact_email: string;
    contact_phone: string;
    link: string;
    facebook: string;
    misc1: string;
    misc2: string;
    misc3: string;
    misc4: string;
    misc5: string;
    advertisement: string;
    show_schedule: string;
    entry_form: string;
    prize_list: string;
    aaq: Boolean;

    constructor() {
        this.name = '';
        this.location = '';
        this.address_one = '';
        this.address_two = '';
        this.city = '';
        this.state = 1;
        this.zip = '';
        this.date_start = null;
        this.date_end = null;
        this.details = '';
        this.contact = '';
        this.contact_email = '';
        this.contact_phone = '';
        this.link = '';
        this.facebook = '';
        this.misc1 = '';
        this.misc2 = '';
        this.misc3 = '';
        this.misc4 = '';
        this.misc5 = '';
        this.advertisement = '';
        this.show_schedule = '';
        this.entry_form = '';
        this.prize_list = '';
        this.aaq = false;
    }
}

export class Show {
    name: string;
    location: string;
    city: string;
    state: string;
    permalink: string;
    folder: string;
    date_start: Date;
    date_end: Date;
    judge: string;
    usaf: boolean;
    final_form: boolean;
    nr_of_horses: number;
    photo_link: string;
    videos: boolean;
    judge_cards: boolean;
    albums: boolean;
    aa: boolean;

    constructor() {
        this.name = '';
        this.location = '';
        this.permalink = '';
        this.folder = '';
        this.city = '';
        this.state = '';
        this.date_start = null;
        this.date_end = null;
        this.judge = '';
        this.usaf = false;
        this.final_form = false;
        this.nr_of_horses = 0;
        this.photo_link = '';
        this.videos = false;
        this.judge_cards = false;
        this.albums = false;
        this.aa = false;
    }
}

export class Page {
    content: string;
    name: string;
    images: string;
    path: string;
    permalink: string;
    banner_position: string;
    position: string;
    meta_title: string;
    meta_keywords: string;
    meta_description: string;
    photographer_id: number;

    constructor() {
        this.content = '';
        this.name = '';
        this.images = '';
        this.path = '';
        this.permalink = '';
        this.banner_position = '';
        this.position = '';
        this.meta_title = '';
        this.meta_keywords = '';
        this.meta_description = '';
        this.photographer_id = null;
    }
}

export class Subscription {
    starting_date: Date;
    expiration_date: Date;
    status: string;

    constructor() {
        this.starting_date = new Date();
        this.expiration_date = new Date();
        this.status = 'pending';
    }
}

export class AutoRenewal {
    cancelled: Boolean;
    renew_date: Date;

    constructor() {
        this.cancelled = false;
        this.renew_date = new Date();
    }
}

export class OrderSubscription {
    category: number;
    subscription: number;

    constructor() {
        this.category = null;
        this.subscription = null;
    }
}

export class OrderItem {
    category: number;
    item: number;
    year: number;
    album: number;
    image: number;

    constructor() {
        this.category = null;
        this.item = null;
        this.year = new Date().getFullYear();
        this.album = null;
        this.image = null;
    }
}

export class FarmProduct {
    id:number;
    user_id:number;
    user_name:string;
    name: string;
    listing_type: string;
    directory_url: string;
    type: number;
    offer: string;
    offer_id: number;
    renew_offer: number;
    expiration_date: string;
    service: string;
    category: number;
    address: string;
    address_2: string;
    state: string;
    state_message: string;
    country: string;
    city: string;
    zip_code: string;
    zip_code_message: string;
    email: string;
    url: string;
    facebook: string;
    instagram: string;
    youtube: string;
    contact: string;
    contact_2: string;
    contact_3: string;
    contact_1_type: string;
    contact_2_type: string;
    contact_3_type: string;
    phone: string;
    phone_2: string;
    phone_3: string;
    phone_1_type: string;
    phone_2_type: string;
    phone_3_type: string;
    logo: string;
    country: string;

    constructor() {
        this.id = null;
        this.user_id = null;
        this.user_name = null;
        this.name = '';
        this.listing_type = '';
        this.directory_url = '';
        this.type = 0;
        this.offer = '';
        this.offer_id = null;
        this.renew_offer = null;
        this.expiration_date = '';
        this.service = '';
        this.category = 0;
        this.address = '';
        this.address_2 = '';
        this.state = null;
        this.state_message = '';
        this.country = 'US';
        this.city = '';
        this.zip_code = '';
        this.zip_code_message = '';
        this.email = '';
        this.url = '';
        this.facebook = '';
        this.instagram = '';
        this.youtube = '';
        this.contact = '';
        this.contact_2 = '';
        this.contact_3 = '';
        this.contact_1_type = '';
        this.contact_2_type = '';
        this.contact_3_type = '';
        this.phone = '';
        this.phone_2 = '';
        this.phone_3 = '';
        this.phone_1_type = '';
        this.phone_2_type = '';
        this.phone_3_type = '';
        this.logo = '';
        this.country = '';
    }
}

export class OrderInformation {
    id:number;
    user_id:number;
    first_name: string;
    last_name: string;
    company: string;
    address: string;
    address2: string;
    city: string;
    state: string;
    zip_code: string;
    phone_number: string;
    country: string;
    email: string;
    zip_code_message: string;
    state_message: string;
    same_as_address: boolean;
    voucher_code:string;

    constructor() {
        this.id = null;
        this.user_id = null;
        this.first_name = "";
        this.last_name = "";
        this.company = "";
        this.address = "";
        this.address2 = "";
        this.city = "";
        this.state = null;
        this.zip_code = "";
        this.phone_number = "";
        this.country = 'US';
        this.email = "";
        this.zip_code_message = "";
        this.state_message = "";
        this.same_as_address = true;
        this.voucher_code = '';

    }
}

export class OrderInfo {
    first_name: string;
    last_name: string;
    company: string;
    address: string;
    address2: string;
    city: string;
    state: string;
    zip_code: string;
    country: string;
    phone_number: string;
    email_address: string;
    transaction: string;
    subtotal: number;
    surcharge: number;
    surcharge_amount: number;
    tax: number;
    shipping_tax: number;
    total: number;
    created_at: Date;
    transaction_id: string;
    discount: number;

    constructor() {
        this.first_name = "";
        this.last_name = "";
        this.company = "";
        this.address = "";
        this.address2 = "";
        this.city = "";
        this.state = "";
        this.zip_code = "";
        this.country = "";
        this.phone_number = "";
        this.email_address = "";
        this.transaction = "";
        this.subtotal = 0;
        this.surcharge = 0;
        this.surcharge_amount = 0;
        this.tax = 0;
        this.shipping_tax = 0;
        this.total = 0;
        this.created_at = new Date();
        this.transaction_id = "";
        this.discount = 0;
    }
}

export class PaymentCard {
    cardNumber: string;
    cardOwner: string;
    cardMonth: number;
    cardYear: number;
    cardCvv: number;
    autoRenewal: boolean;

    constructor() {
        this.cardNumber = '';
        this.cardOwner = null;
        this.cardMonth = 0;
        this.cardYear = 0;
        this.cardCvv = null;
        this.autoRenewal = true;
    }
}

export class PageDownload {
    download_name: string;
    download: string;
    select_option: number;

    constructor() {
        this.download_name = '';
        this.download = '';
        this.select_option = 0;
    }
}

export class FBVideo {
    url: string;
    event_id: number;
    live: boolean;

    constructor() {
        this.url = '';
        this.live = false;
        this.event_id = 0;
    }
}

export class AdminUser {
    first_name: string;
    last_name: string;
    email: string;
    account_type: number;
    //image: ;
    password: string;
    confirm_password: string;
    status: number;
    sales_rep: number;

    constructor() {
        this.first_name = '';
        this.last_name = '';
        this.email = '';
        this.account_type = 1;
        //this.image =;
        this.password = '';
        this.confirm_password = '';
        this.status = 0;
        this.sales_rep = 0;
    }

}

export class User {

    first_name: string;
    last_name: string;
    company: string;
    address: string;
    address2: string;
    city: string;
    state: string;
    zip_code: string;
    country: string;
    phone_number: string;
    email: string;
    password: string;
    confirm_password: string;
    notes: string;
    state_message: string;
    zip_code_message: string;

    constructor() {

        this.first_name = "";
        this.last_name = "";
        this.company = "";
        this.address = "";
        this.address2 = "";
        this.city = "";
        this.state = null;
        this.zip_code = "";
        this.country = 'US';
        this.phone_number = "";
        this.email = "";
        this.password = "";
        this.confirm_password = "";
        this.notes = "";
        this.state_message = '';
        this.zip_code_message = "";
    }
}

export class UserShippingAddresses {

    id: number;
    shipping_id: number;
    first_name: string;
    last_name: string;
    company: string;
    address: string;
    address2: string;
    city: string;
    state: string;
    zip_code: string;
    country: string;
    phone_number: string;
    see_shipping: boolean;
    message: string;
    state_message: string;
    zip_code_message: string;
    log: string;

    constructor() {
        this.id = 0;
        this.shipping_id = null;
        this.first_name = "";
        this.last_name = "";
        this.company = null;
        this.address = "";
        this.address2 = null;
        this.city = "";
        this.state = null;
        this.zip_code = "";
        this.country = 'US';
        this.phone_number = null;
        this.see_shipping = true;
        this.message = null;
        this.state_message = '';
        this.zip_code_message = "";
        this.log = "";
    }
}

export class BillingInformation {
    first_name: string;
    last_name: string;
    company: string;
    address: string;
    address2: string;
    city: string;
    state: string;
    zip_code: string;
    phone_number: string;
    country: string;
    same_as_address: boolean;
    contact_type: string;
    other_phone: string;
    other_contact_type: string;
    email: string;
    spouse: number;
    children: [];
    facebook: string;
    website: string;
    stable: [];
    owner: [];
    rider: [];
    cc: number;
    past_due: number;
    cc_last_digits: number;
    cc_expiration_date: string;
    past_due_amount: number;
    account_notes: string;
    state_message: string;
    zip_code_message: string;

    constructor() {
        this.first_name = null;
        this.last_name = null;
        this.company = null;
        this.address = null;
        this.address2 = null;
        this.city = null;
        this.state = null;
        this.zip_code = null;
        this.phone_number = null;
        this.country = 'US';
        this.same_as_address = false;
        this.contact_type = null;
        this.other_phone = null;
        this.other_contact_type = null;
        this.email = null;
        this.spouse = null;
        this.children = [];
        this.facebook = null;
        this.website = null;
        this.stable = [];
        this.owner = [];
        this.rider = [];
        this.cc = 0;
        this.past_due = 0;
        this.cc_last_digits = 0;
        this.cc_expiration_date = null;
        this.past_due_amount = 0;
        this.account_notes = null;
        this.state_message = '';
        this.zip_code_message = "";
    }
}

export class AdInformation {

    ad_contact: string;
    ad_contact_phone: string;
    ad_contact_email: string;
    design_instructions: string;
    ad_instructions: string;
    ad_copy: string;
    sales_represent: number;

    constructor() {

        this.ad_contact = null;
        this.ad_contact_phone = null;
        this.ad_contact_email = null;
        this.design_instructions = null;
        this.ad_instructions = null;
        this.ad_copy = null;
        this.sales_represent = null;
    }
}

export class SpouseChild {
    first_name: string;
    last_name: string;
    email: string;
    password: string;
    confirm_password: string;
    message: string;
    constructor() {
        this.first_name = null;
        this.last_name = null;
        this.email = null;
        this.password = null;
        this.confirm_password = null;
        this.message = null;
    }
}

export class Tag {
    id: number;
    name: string;
    color: string;

    constructor() {
        this.id = -1;
        this.name = '';
        this.color = '#9500ff';
    }
}

export class Breed {
    name: string;
    open_breed: boolean;

    constructor() {
        this.name = '';
        this.open_breed = false;
    }
}

export class Division {
    name: string;
    breed_id: number;

    constructor() {
        this.name = '';
        this.breed_id = null;
    }
}

export class ResultClass {
    name: string;
    breed_id: number;
    division_id: number;
    aaad_id: string;
    championship_class: boolean;
    in_hand_class: boolean;

    constructor() {
        this.name = '';
        this.breed_id = null;
        this.division_id = null;
        this.aaad_id = null;
        this.championship_class = false;
        this.in_hand_class = false;
    }
}

export class Aaad {
    name: string;
    breed_id: number;
    division_id: number;
    resultClass_id: number;

    constructor() {
        this.name = null;
        this.breed_id = null;
        this.division_id = null;
        this.resultClass_id = null;
    }
}

export class Stable {
    name: string;
    message: string;

    constructor() {
        this.name = '';
        this.message = '';
    }
}

export class Owner {
    first_name: string;
    last_name: string;
    state_id: number;
    country_id: number;
    notes: string;
    phone_number: string;
    phone_number_two: string;
    permalink: string;

    constructor() {
        this.first_name = '';
        this.last_name = '';
        this.state_id = null;
        this.country_id = 1;
        this.notes = '';
        this.phone_number = '';
        this.phone_number_two = '';
        this.permalink = '';
    }
}

export class Horse {
    name: string;
    breed_id: number;
    breed: string;
    owner_id: number;
    owner: string;
    owner_permalink: string;
    second_owner_id: number;
    second_owner: string;
    second_owner_permalink: string;
    sire_id: number;
    sire: string;
    sire_permalink: string;
    dam_id: number;
    dam: string;
    dam_permalink: string;
    stable_id: number;
    stable: string;
    gender: number;
    sales_rep: number;
    sales_rep_name: string;
    permalink: string;
    notes: string;
    birthdate: Date;
    ch: boolean;
    ch_eq: boolean;
    deceased: boolean;

    constructor() {
        this.name = '';
        this.breed_id = null;
        this.breed = '';
        this.owner_id = null;
        this.owner = '';
        this.owner_permalink = '';
        this.second_owner_id = null;
        this.second_owner = '';
        this.second_owner_permalink = '';
        this.sire_id = null;
        this.sire = '';
        this.sire_permalink = '';
        this.dam_id = null;
        this.dam = '';
        this.dam_permalink = '';
        this.stable_id = null;
        this.stable = '';
        this.gender = null;
        this.sales_rep = null;
        this.sales_rep_name = '';
        this.permalink = '';
        this.notes = '';
        this.birthdate = null;
        this.ch = false;
        this.ch_eq = false;
        this.deceased = false;
    }
}

export class Rider {
    first_name: string;
    last_name: string;
    stable_id: number;
    state_id: number;
    state: string;
    permalink: string;
    sales_rep: number;
    notes: string;

    constructor() {
        this.first_name = '';
        this.last_name = '';
        this.stable_id = null;
        this.state_id = null;
        this.sales_rep = null;
        this.permalink = '';
        this.state = '';
        this.notes = '';
    }
}

export class Result {
    judge: string;
    photo_link: string;
    event_id: number;
    nr_of_horses: number;
    usaf: boolean;
    final_form: boolean;
    real_time_show: boolean;

    constructor() {
        this.judge = '';
        this.photo_link = '';
        this.event_id = 0;
        this.nr_of_horses = 0;
        this.usaf = false;
        this.final_form = false;
        this.real_time_show = false;
    }
}

export class ResultClassSetting {
    result_id: number;
    class_id: number;
    nr_of_horses: string;
    internal_class_id: string;

    constructor() {
        this.result_id = 0;
        this.class_id = 0;
        this.nr_of_horses = '';
        this.internal_class_id = '';
    }
}

export class ResultPoint {
    class_settings_id: number;
    horse_id: number;
    rider_id: number;
    horse_stable: number|null;
    horse_sales_rep: number|null;
    place: number;
    horse_points: number;
    rider_points: number;
    focus_horse: boolean;
    focus_rider: boolean;

    constructor() {
        this.class_settings_id = 0;
        this.horse_id = null;
        this.rider_id = null;
        this.horse_stable = null;
        this.horse_sales_rep = null;
        this.place = 0;
        this.horse_points = 0;
        this.rider_points = 0;
        this.focus_horse = false;
        this.focus_rider = false;
    }
}

export class AdvertiseTestimonial {
    text: string;
    who: string;
    from: string;

    constructor() {
        this.text;
        this.who;
        this.from;
    }
}

export class News {
    id: number;
    entry_title: string;
    entry_content: string;
    entry_status: string;
    image: string;

    constructor() {
        this.id = null;
        this.entry_title = '';
        this.entry_content = '';
        this.entry_status = 'active';
        this.image = '';
    }
}

export class AdvertiseCategory {
    category_name: string;
    thumbnail: string;

    constructor() {
        this.category_name;
        this.thumbnail;
    }
}

export class TeamMember {
    id: number;
    name: string;
    category_id: string;
    city_state: string;
    phone_number: string;
    image: string;
    cached_image: string;
    email: string;

    constructor() {
        this.id;
        this.name;
        this.category_id;
        this.city_state;
        this.phone_number;
        this.email;
        this.image;
        this.cached_image;
    }
}

export class TeamMemberCategory {
    name: string;
    color: string;

    constructor() {
        this.name;
        this.color;
    }
}

export class Issue {
    id: number;
    created_at: string;
    name: string;
    cover: string;
    definition_issue_id: number;
    definition_issue: string;
    status: string;
    selected: number;
    feauturing: string;

    constructor() {

        this.id;
        this.created_at;
        this.name;
        this.cover;
        this.definition_issue_id;
        this.definition_issue;
        this.status;
        this.selected;
        this.feauturing;
    }
}

export class Widget {
    title: string;
    description: string;
    image: string;
    original_image: string;
    url: string;
    position: string;
    news_active: boolean;

    constructor() {
        this.title='';
        this.description='';
        this.image='';
        this.original_image='';
        this.url='';
        this.position='';
        this.news_active=false;
    }
}

export class Offer {
    image_cached: string;
    album_path: string;
    active: number;
    type_id: number;
    product_type: number;
    title: string;
    illustrations: string;
    description: string;
    publication_id: number;
    product_image: string;
    price: number;
    country: string;
    subscription_type: number;
    subscription_period: number;
    item_type: number;
    visible_on_site: number;
    shipping_tax: number;
    shipping_tax_country: string;
    shipping_tax_us: number;
    shipping_tax_ca: number;
    shipping_tax_za: number;
    constructor() {
        this.album_path = '';
        this.image_cached = '';
        this.active = 0;
        this.type_id = 1;
        this.product_type = 2;
        this.title = '';
        this.illustrations = '';
        this.description = '';
        this.product_image = '';
        this.price = 0;
        this.country = '';
        this.subscription_type = 1;
        this.subscription_period;
        this.item_type = 1;
        this.visible_on_site = 0;
        this.shipping_tax = 0;
        this.shipping_tax_country = '';
        this.shipping_tax_us = 0;
        this.shipping_tax_ca = 0;
        this.shipping_tax_za = 0;
        this.publication_id = 1;
    }
}

export class DefinitionIssue {
    date: Date;
    title: string;
    ignore_in_reports: number;
    constructor() {
        this.date = null;
        this.title = '';
        this.ignore_in_reports = 0;
    }
}

export class Galery {
    name: string;
    album_date: Date;
    description: string;
    order_availability: boolean;
    edit_name: boolean;
    file_size: number;

    constructor() {
        this.name = '';
        this.album_date = null;
        this.description = '';
        this.order_availability = false;
        this.edit_name = false;
        this.file_size = 0;
    }
}

export class Video {
    title: string;
    video_url: string;
    video_date: Date;
    video_source: string;
    video_embeded: string;
    constructor() {
        this.video_url = '';
        this.title = '';
        this.video_date = null;
        this.video_source = '';
        this.video_embeded = '';
    }
}

export class TaxRate {
    country: string;
    state: string;
    rate: number;
    amount: number;
    type: number;
    constructor() {
        this.country = "US";
        this.state = '';
        this.rate = 0;
        this.amount = 0;
        this.type = 1;
    }
}

export class Publication {
    publication: string;
    id: number;
    constructor() {
        this.publication = '';
        this.id = 0;
    }
}

export class Bundle {
    active: number;
    title: string;
    illustrations: string;
    content: string;
    price: number;
    visible_on_site: number;
    constructor() {
        this.active = 0;
        this.title = '';
        this.illustrations = '';
        this.content = '';
        this.price = 0;
        this.visible_on_site = 0;
    }
}

export class ReportSubscription{
    type:string;
    country: string;
    status: string;
    range: boolean;
    starting_date: Date;
    expiration_date: Date;
    constructor() {
        this.type = 'Print';
        this.country = 'US';
        this.status = 'active';
        this.range = false;
        this.starting_date = null;
        this.expiration_date = null;
    }
}

export class ReportSubscriptionIssue{
    issueLastDate:Date;
    issueNextDate: Date;
    last_issue: Date;
    constructor() {
        this.issueLastDate = null;
        this.issueNextDate = null;
        this.last_issue = null;
    }
}

export class ReportRevenue {
    period_type: number;
    period_year: number;
    period_month: number;
    period_1: Date;
    period_2: Date;
    category: number;
    album: number;
    photographer: number;
    item: number;
    purchase_type: number;

    constructor() {
        this.period_type = 1;
        this.period_year = new Date().getFullYear();
        this.period_month = 0;
        this.period_1 = null;
        this.period_2 = null;
        this.category = null;
        this.album = null;
        this.photographer = null;
        this.item = null;
        this.purchase_type = 0;
    }
}

export class ReportResults {
    year: string;
    show: number;
    sales_rep: number;
    stable: number;
    constructor() {
        this.year = null;
        this.show = null;
        this.sales_rep = null;
        this.stable = null;
    }
}

export class Campaigns {
    id: number;
    campaign_name: string;
    expire_date: string;
    voucher_name: string;
    discount_type: number;
    discount: number;
    voucher_no: number;
    voucher_type: number;
    category: string;
    constructor() {
        this.id = 0;
        this.campaign_name = '';
        this.expire_date = '';
        this.voucher_name = '';
        this.discount_type = 0;
        this.discount = null;
        this.voucher_no = null;
        this.voucher_type = 0;
        this.category = null;
    }
}

export class FileManager {
    path: string;
    selected_file: string;
    selected_type: string;
    selected_name: string;
    copy_file: string;
    copy_type: string;
    cut_file: string;
    cut_type: string;
    constructor() {
        this.path = '';
        this.selected_file = '';
        this.selected_type = '';
        this.selected_name = '';
        this.copy_file = '';
        this.copy_type = '';
        this.cut_file = '';
        this.cut_type = '';
    }
}

export class ReportRiderPoints {
    type: number;
    year: string;
    show: number;
    constructor() {
        this.type = 0;
        this.year = null;
        this.show = null;
    }
}

export class ReportAAA {
    type: number;
    year: string;
    breed: number;
    sales_rep: number;
    constructor() {
        this.type = 0;
        this.year = null;
        this.breed = null;
        this.sales_rep = null;
    }
}

export class ReportSire {
    report_type: number;
    type: number;
    year: string;
    breed: number;
    show: number;
    constructor() {
        this.report_type = 1;
        this.type = 1;
        this.year = null;
        this.breed = null;
        this.show = null;
    }
}

export class Career {
    title: string;
    category: number;
    overview: string;
    term: number;
    compensation: number;
    amount: string;
    year_experience: string;
    education_level: string;
    details: string;
    reference_code: string;
    location_name: string;
    address_1: string;
    address_2: string;
    city: string;
    state: number;
    zip: string;
    company: number;
    contact_title: string;
    first_name: string;
    last_name: string;
    email: string;
    phonenumber: string;
    fax: string;
    website: string;
    status: string;
    created_at: Date;
    constructor() {
        this.title = '';
        this.category = null;
        this.overview = '';
        this.term = null;
        this.compensation = null;
        this.amount = '';
        this.year_experience = '';
        this.education_level = '';
        this.details = '';
        this.reference_code = '';
        this.location_name = '';
        this.address_1 = '';
        this.address_2 = '';
        this.city = '';
        this.state = null;
        this.zip = '';
        this.company = null;
        this.contact_title = '';
        this.first_name = '';
        this.last_name = '';
        this.email = '';
        this.phonenumber = '';
        this.fax = '';
        this.website = '';
        this.status = 'active';
        this.created_at = null;
    }
}

export class EmploymentCompany {
    name: string;
    website: string;
    overview: string;
    status: string;
    image: string;
    constructor() {
        this.name = '';
        this.website = '';
        this.overview = '';
        this.image = '';
        this.status = 'active';
    }
}

export class Photographer {
    name: string;
    constructor() {
        this.name = '';
    }
}

export class Advertiser {
    name: string;
    constructor() {
        this.name = '';
    }
}

export class Banner {
    subscription_id: number;
    user_id: number;
    banner_type: number;
    title: string;
    link: string;
    image: string;
    image_mobile: string;
    pages: Array<any>;
    constructor() {
        this.subscription_id = null;
        this.user_id = null;
        this.banner_type = 0;
        this.title = '';
        this.link = '';
        this.image = '';
        this.image_mobile = '';
        this.pages = [];
    }
}

export class HorseSaleDivision {

}

export class HorseSale {
    user_id: number;
    horse_name: string;
    registry_number: string;
    breed: number;
    sire: string;
    dam: string;
    date_of_birth: Date;
    owner: string;
    stable: string;
    stable_phone: string;
    state: string;
    city: string;
    agent: string;
    agent_phone: string;
    agent_email: string;
    price: string;
    division: Object;
    additional_information: string;
    image: string;
    image2: string;
    image3: string;
    image4: string;
    image5: string;
    image6: string;
    youtube_url: string;
    facebook_url: string;
    image_option: number;
    just_sold: number;
    cover: string;
    horse_type: string;
    constructor() {
        this.user_id = null;
        this.horse_name = '';
        this.registry_number = '';
        this.breed = null;
        this.sire = '';
        this.dam = '';
        this.date_of_birth = null;
        this.owner = '';
        this.stable = '';
        this.stable_phone = '';
        this.state = '';
        this.city = '';
        this.agent = '';
        this.agent_phone = '';
        this.agent_email = '';
        this.price = '';
        this.division = new HorseSaleDivision();
        this.additional_information = '';
        this.image = '';
        this.image2 = '';
        this.image3 = '';
        this.image4 = '';
        this.image5 = '';
        this.image6 = '';
        this.youtube_url = '';
        this.facebook_url = '';
        this.image_option = null;
        this.just_sold = null;
        this.cover = '';
        this.horse_type = 'sales';
    }
}

export class HorseStallion {
    name: string;
    image: string;
    breed: number;
    owner: string;
    standing: string;
    contact: string;
    contact_type: string;
    phone: string;
    phone_type: string;
    email_url: string;
    status: string;
    constructor() {
        this.name = '';
        this.image = '';
        this.breed = 0;
        this.owner = '';
        this.standing = '';
        this.contact = '';
        this.contact_type = '';
        this.phone = '';
        this.phone_type = '';
        this.email_url = '';
        this.status = 'active';
    }
}

export class MergeUser1 {
    email: string;
    first_name: string;
    last_name: string;
    constructor() {
        this.email = '';
        this.first_name = '';
        this.last_name = '';
    }
}

export class MergeUser2 {
    address: string;
    address2: string;
    city: string;
    company: string;
    country: string;
    notes: string;
    phone_number: string;
    state: string;
    zip_code: string;
    constructor() {
        this.address = '';
        this.address2 = '';
        this.city = '';
        this.company = '';
        this.country = '';
        this.notes = '';
        this.phone_number = '';
        this.state = '';
        this.zip_code = '';
    }
}

export class MergeUser3 {
    extra_info: number;
    add_info: number;
    add_name: string;
    extra_name: string;

    constructor() {
        this.extra_info = 0;
        this.add_info = 0;
        this.add_name = '';
        this.extra_name = '';
    }
}

export class ContactForm {
    first_name: string;
    last_name: string;
    email: string;
    phone: string;
    message: string;
    department: string;

    constructor() {
        this.first_name = '';
        this.last_name = '';
        this.email = '';
        this.phone = '';
        this.message = '';
        this.department = '';
    }
}

export class AuthenticationUser {
    first_name: string;
    last_name: string;
    email: string;
    password: string;
    confirm_password: string;

    constructor() {
        this.first_name = '';
        this.last_name = '';
        this.email = '';
        this.password = '';
        this.confirm_password = '';
    }
}